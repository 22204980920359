import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, validate } from "class-validator";

import { Header,Footer } from "@/layout";
import { APLightbox } from "@/components";
import { QuizModel } from "@/models";
import { TeCommon } from "../Common";
import { ErrorCode, ErrorMessage } from "@/constant";

export class PaperForm{
    name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) beginTime = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) endTime = '';
    city = "";
    school = "";
    class = "";
    teacher = "";
    grade = '';
    level = "";
    unit = "";
    num = 0;
    active = "";
    qid = '';
    token = window.localStorage.getItem('teacherToken') as string;
}

type response = { [key: string]: unknown };

@Component<ApTeacherTestPaperAddController>({
    components: {
        Header, APLightbox,Footer
    }
})
export default class ApTeacherTestPaperAddController extends Vue {
    /*定義data 變數 */
    private paperForm = new PaperForm();
    private doubleClick = false;
    private name = window.localStorage.getItem('teacher_name') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    private errorMsgMap: { [key: string]: string } = {
        beginTime: '',
        endTime: '',
    };

    public async created() {
        const item: { [key: string]: string } = {
            qid: this.$route.query.qid as string,
            token: this.token,
        }
        const editData = await QuizModel.teGetPaper(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            qid: string;
            name: string;
            beginTime: string;
            endTime: string;
            city: string;
            school: string;
            class: string;
            teacher: string;
            grade: string;
            unit: string;
            level: string;
            num: number;
            active: string;
        };
        this.paperForm.qid = item.qid;
        this.paperForm.name = item.name;
        this.paperForm.beginTime = item.beginTime;
        this.paperForm.endTime = item.endTime;
        this.paperForm.city = item.city;
        this.paperForm.school = item.school;
        this.paperForm.class = item.class;
        this.paperForm.teacher = item.teacher;
        this.paperForm.grade = item.grade;
        this.paperForm.unit = item.unit;
        this.paperForm.level = item.level;
        this.paperForm.num = item.num;
        this.paperForm.active = item.active;
    }

    private validateAndSubmit() {
        validate(this.paperForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        beginTime: '',
                        endTime: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const errNo = await QuizModel.teEditPaper(this.paperForm);
                    switch (errNo) {
                        case ErrorCode.InvalidToken:
                            TeCommon.logout();
                            break;
                        case ErrorCode.Success:
                            this.doubleClick = false;
                            this.$router.push("/teacher/quiz/paper");
                            break;
                        default:
                            this.lightbox.errorMsg = ErrorMessage[errNo];
                            this.lightbox.showLB = true;
                            this.doubleClick = false;
                            break;
                    }
                }
            }
        )
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}